<template>
  <div>
    <RoleCard
      v-if="track"
      role="school">
      <template #label>{{ track.title }}</template>
      <template #label_info
        >Шаг 3 из 3 – Выбор {{ isJunior ? "сферы" : "профиля" }}</template
      >
      <template #body>
        <div
          v-if="pending"
          class="panel-placeholder">
          <span><BaseSpinner brand />&emsp;</span>
          <span>Подождите....</span>
        </div>
        <div v-else>
          <div v-if="isJunior">
            <div class="mb-l row">
              <div class="col-md-8 col-xs-12">
                <p class="text-bold mb-s">Выбор сферы</p>
                <p class="mb-m">
                  Можно выбрать только одну сферу. Если ты&nbsp;пока
                  не&nbsp;понимаешь, какая сфера тебе интересна, поставь галочку
                  &laquo;Я&nbsp;выберу сферу позже&raquo; и&nbsp;пройди наши
                  образовательные курсы, чтобы познакомиться со&nbsp;всеми
                  сферами.
                </p>
              </div>
            </div>
            <div>
              <div class="scroll-content profile-list">
                <ProfileCard
                  v-for="item in juniorProfiles"
                  :id="item.id"
                  :key="item.id"
                  :selected="selectedProfile === item.id"
                  :selectable="item.isSelectable"
                  @select="handleSelectProfile">
                  <div>{{ item.title }}</div>

                  <div
                    v-if="item.scheduleError"
                    class="form-error">
                    {{ item.scheduleError }}
                  </div>
                  <div
                    v-else-if="!item.hasfinalEvents"
                    class="form-error">
                    У данной сферы пока не определены площадки для финала
                  </div>

                  <div
                    v-else-if="!currentEducation"
                    class="form-error">
                    У вас не указано текущее образование
                  </div>
                  <div
                    v-else-if="!is4GradeJunior && !item.finalEvent"
                    class="form-error">
                    Не проводится в
                    {{ currentEducation.region_with_type }}. Обратитесь в
                    <a
                      :href="`mailto:${$store.state.supportEmail}`"
                      class="link"
                      >службу поддержки</a
                    >
                  </div>
                  <div
                    v-else
                    class="text-size-s"
                    style="font-weight: 400">
                    <!-- <span v-if="item.finalEvent.format === 'offline'">
                      Финал этой сферы будет проходить в очном формате в
                      регионе:
                      {{ item.finalEvent.region_with_type }}
                    </span>
                    <span v-else
                      >Финал этой сферы будет проходить в онлайн-формате</span
                    > -->
                    <div
                      v-if="item.reason"
                      class="text-size-s color-meta mt-s">
                      {{ item.reason }}
                    </div>
                  </div>
                </ProfileCard>
              </div>

              <div class="no-ready mt-l">
                <RegCheckbox
                  :checked="imNotReady"
                  class="no-ready__check"
                  @input="handleReady" />
                <span
                  class="no-ready__text"
                  @click="handleReady">
                  Я выберу сферу позже</span
                >
              </div>
            </div>
          </div>

          <div v-else>
            <p class="text-bold mb-s">Выбор профиля</p>
            <p>
              Мы&nbsp;просим вас заранее выбрать один профиль, в&nbsp;котором
              вы&nbsp;собираетесь участвовать. Остальные профили можно будет
              выбрать в&nbsp;личном кабинете.
            </p>
            <div class="scroll-content profile-list">
              <ProfileCard
                v-for="item in profiles"
                :id="item.id"
                :key="item.id"
                :selected="selectedProfile === item.id"
                :selectable="item.isSelectable"
                @select="handleSelectProfile">
                <div>{{ item.title }}</div>
                <template #meta>
                  <div
                    v-if="item.scheduleError"
                    class="form-error">
                    {{ item.scheduleError }}
                  </div>
                  <div v-else>
                    <div>
                      <p>{{ item.description }}</p>
                    </div>
                    <div
                      v-if="item.reason"
                      class="text-size-s color-meta mt-s">
                      {{ item.reason }}
                    </div>
                  </div>
                </template>
              </ProfileCard>
            </div>

            <div
              v-if="hasPolicy"
              class="mb-l">
              <hr class="hr" />
              <div class="row mb-m">
                <div class="col-md-8 col-xs-12">
                  <div v-if="currentProfile.policy_description">
                    {{ currentProfile.policy_description }}
                  </div>
                  <div v-else>
                    Подтверждаю согласие на обработку и передачу персональных
                    данных вузу-организатору профиля
                    <span class="text-bold"
                      >&laquo;{{ currentProfile.title }}&raquo;</span
                    >:
                  </div>
                </div>
              </div>
              <div
                v-if="currentProfile.policy_url"
                class="mb-s">
                <RegCheckbox
                  :checked="confirmPolicy"
                  class="no-ready__check"
                  @input="confirmPolicy = !confirmPolicy" />
                <span @click="confirmPolicy = !confirmPolicy">
                  в соответствии с
                  <a
                    :href="currentProfile.policy_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="link"
                    @click.stop
                    >политикой обработки персональных данных</a
                  >
                </span>
              </div>
              <div v-if="currentProfile.consent_url">
                <RegCheckbox
                  :checked="confirmConsent"
                  :value="confirmConsent"
                  class="no-ready__check"
                  @input="confirmConsent = !confirmConsent" />
                <span @click="confirmConsent = !confirmConsent">
                  в соответствии с
                  <a
                    :href="currentProfile.consent_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="link"
                    @click.stop
                    >согласием на обработку данных</a
                  >
                </span>
              </div>

              <div
                v-if="policyError"
                class="form-error mt-m">
                Для того, чтобы выбрать этот профиль, необходимо согласиться с
                политиками конфиденциальности.
              </div>
            </div>

            <div class="no-ready">
              <RegCheckbox
                :checked="imNotReady"
                class="no-ready__check"
                @input="handleReady" />
              <span
                class="no-ready__text"
                @click="handleReady">
                Я выберу профиль позже</span
              >
            </div>
          </div>

          <hr class="hr" />

          <div
            v-if="error"
            class="form-error mb-m">
            {{ error }}
          </div>
          <div class="btn-row">
            <BaseButton
              type="button"
              :disabled="formPending"
              @click="handleSubmit"
              >Перейти в личный кабинет</BaseButton
            >
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import shuffle from "lodash/shuffle";
import { talentRequest } from "@/services/api";
import RoleCard from "@/components/registration/RoleCard.vue";
import ProfileCard from "@/components/registration/ProfileCard.vue";
import RegCheckbox from "@/components/registration/RegCheckbox";
import { setUTMCookie } from "@/utils/cookies";
import { FINAL_STAGE } from "@/constants";
import { getJuniorFinalEvent } from "@/utils/participant";
import { ScheduleService } from "@/services/scheduleService";

export default {
  name: "RegistrationProfile",
  components: {
    RoleCard,
    ProfileCard,
    RegCheckbox,
  },
  metaInfo() {
    return {
      title: "Регистрация - Выбор профиля",
    };
  },
  data() {
    return {
      selectedProfile: null,
      error: "",
      achievementsRoutes: {},
      pending: true,
      formPending: false,
      imNotReady: false,
      confirmPolicy: true,
      confirmConsent: true,
      policyError: false,
    };
  },
  computed: {
    ...mapGetters({
      profilesList: "profile/profiles",
      isJunior: "participant/isJunior",
      currentEducation: "participant/currentEducation",
      user: "user/user",
    }),
    events() {
      return this.$store.state.profile.events;
    },
    track() {
      return this.$store.state.participant.track;
    },
    profiles() {
      const { profilesList } = this;
      const selected = this.$route.query.profile_id;
      const recommendedIds = [];
      if (selected) recommendedIds.push(+selected);
      if (this.$route.query.profiles) {
        // Рекомендованные профили при регистрации от школы
        // по тематическому направлению
        this.$route.query.profiles.split(",").forEach((n) => {
          recommendedIds.push(+n);
        });
      }
      const currentList = profilesList.map((profile) => {
        const result = {
          title: profile.title,
          id: profile.id,
          steps: profile.steps,
          description: profile.short_description,
          order: 0,
          isSelectable: true,
          scheduleError: ScheduleService.checkSelectStage(
            profile.id,
            this.schedule,
            this.isJunior
          ),
        };

        if (result.scheduleError) {
          result.isSelectable = false;
          result.order -= 200;
        }

        if (profile.recommended) {
          result.order += 1;
        }

        if (recommendedIds.includes(profile.id)) {
          result.order += 100;
        }

        if (profile.routes) {
          profile.routes.forEach((route) => {
            if (this.achievementsRoutes[route.id]) {
              result.order += this.achievementsRoutes[route.id];
            }
          });
        }

        if (result.order >= 100) {
          result.reason = "Рекомендован по реферальной ссылке";
        } else if (
          (!profile.recommended && result.order >= 1) ||
          (profile.recommended && result.order >= 2)
        ) {
          result.reason = "Рекомендован на основе ваших достижений";
        }

        return result;
      });
      const sorted = currentList.sort((a, b) => {
        return b.order - a.order;
      });

      // shuffle
      const grouped = sorted.reduce(
        (acc, value) => {
          if (value.order > 1) {
            acc[0].push(value);
          } else if (value.order === 1) {
            acc[1].push(value);
          } else {
            acc[2].push(value);
          }
          return acc;
        },
        [[], [], []]
      );
      return [...grouped[0], ...shuffle(grouped[1]), ...shuffle(grouped[2])];
    },
    juniorProfiles() {
      const { profiles, isJunior, currentEducation } = this;
      if (!isJunior) return;

      const list = profiles.map((profile) => {
        const events = profile.steps
          ?.filter((step) => step.stage === FINAL_STAGE && step.talent_event_id)
          .map((step) => {
            return this.events[step.talent_event_id];
          });

        const finalEvent = getJuniorFinalEvent(events, currentEducation);
        return {
          ...profile,
          finalEvent,
          hasfinalEvents: events?.length > 0,
          order: finalEvent ? profile.order + 10 : profile.order - 200,
          isSelectable:
            profile.isSelectable && (this.is4GradeJunior || !!finalEvent),
        };
      });
      return list.sort((a, b) => {
        return b.order - a.order;
      });
    },
    currentProfile() {
      if (!this.selectedProfile) return;
      return this.profilesList.find((n) => n.id === this.selectedProfile);
    },
    hasPolicy() {
      const { currentProfile } = this;
      if (!currentProfile) return;
      return !!currentProfile.policy_url || !!currentProfile.consent_url;
    },
    is4GradeJunior() {
      return this.isJunior && this.user?.participant?.grade === 4;
    },
    schedule() {
      return this.$store.state.schedule;
    },
  },
  created() {
    setUTMCookie(this.$route.query);
    this.init();
  },
  methods: {
    handleSelectProfile(id) {
      if (this.selectedProfile === id) {
        this.selectedProfile = null;
      } else {
        this.selectedProfile = id;
        this.imNotReady = false;
      }
      this.confirmPolicy = true;
      this.confirmConsent = true;
      this.policyError = false;
    },
    goNext() {
      const { next } = this.$route.query;
      this.$router.push(
        next
          ? next
          : {
              name: "user-index",
            }
      );
    },
    handleReady() {
      this.imNotReady = !this.imNotReady;
      if (this.imNotReady) {
        this.selectedProfile = null;
      }
    },
    async handleSubmit() {
      const { selectedProfile, imNotReady, isJunior } = this;
      if (this.formPending) return;
      if (imNotReady) {
        this.goNext();
        return;
      }
      if (this.hasPolicy) {
        const { policy_url, consent_url } = this.currentProfile;
        if (
          (policy_url && !this.confirmPolicy) ||
          (consent_url && !this.confirmConsent)
        ) {
          this.policyError = true;
          return;
        }
      }
      this.formPending = true;
      this.error = "";
      if (!selectedProfile && !imNotReady) {
        this.error = `Вы должны выбрать ${
          isJunior ? "сферу" : "профиль"
        } олимпиады`;
        this.formPending = false;
        return;
      }
      const payload = {
        profile_id: selectedProfile,
      };
      if (isJunior && !this.is4GradeJunior) {
        payload.final_event = this.juniorProfiles.find(
          (n) => n.id === selectedProfile
        )?.finalEvent?.id;
      }

      try {
        await this.$store.dispatch("participant/selectProfile", payload);
        this.goNext();
      } catch (error) {
        if (error.message === "Профиль уже выбран") {
          this.formPending = false;
          this.goNext();
          return;
        }
        this.error = error.message;
        this.formPending = false;
        return;
      }
    },

    async init() {
      this.pending = true;
      try {
        // получаем инфо о профилях и участнике
        await this.$store.dispatch("participant/getInfo");
        await this.$store.dispatch("profile/getProfiles");
        await this.$store.dispatch("getSchedule");
      } catch (error) {
        // если не получили, то редиректим в лк.
        // здесь он все-равно выбрать ничего не сможет
        this.goNext();
        return;
      }

      // если профилей нет то и выбирать не из чего
      if (!this.profilesList.length) {
        this.goNext();
        return;
      }

      /**
       * Пробуем получить достижения юзера,
       * чтобы отсортировать список профилей на их основе
       */
      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `/api/users/${this.$store.state.user.talentUser.id}/achievements/`,
          params: {
            limit: 100,
            offset: 0,
            ordering: "-created_at",
          },
        });

        const rec = data.results.reduce((acc, achievement) => {
          if (achievement.event?.routes?.length) {
            achievement.event.routes.forEach((routeId) => {
              if (acc[routeId]) {
                acc[routeId] += 1;
              } else {
                acc[routeId] = 1;
              }
            });
          }
          return acc;
        }, {});

        this.achievementsRoutes = rec;
      } catch (error) {
        // Ну не удалось и не удалось...
        console.log("error", error);
      }

      /**
       * Для джунов нужно получить список мероприятий финала,
       * и список образований, если еще не получали
       */
      if (this.isJunior) {
        await this.$store
          .dispatch("profile/getAllFinalEvents")
          .catch((error) => {
            console.log("error", error);
          });
        await this.$store.dispatch("user/getEducation").catch((error) => {
          console.log("error", error);
        });
      } else {
        const selectable = this.profiles?.find((n) => n.isSelectable);
        this.selectedProfile = selectable?.id;
      }

      this.pending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-content {
  max-height: 400px;
}
.panel-placeholder {
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-list {
  border: 1px solid @lp-outline-color;
  border-radius: 4px;
  margin: 40px 0;
}

.city-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &__btn {
    .base-icon {
      margin-top: -2px;
    }
  }
}

.no-ready {
  &__check {
    margin-right: 10px;
  }
}
</style>
