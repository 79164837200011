<template>
  <div
    class="reg-checkbox"
    :class="{ 'is-checked': checked, 'is-disabled': disabled }"
    @click="handleClick"></div>
</template>

<script>
export default {
  name: "RegCheckbox",
  props: {
    value: {
      type: [String, Number, Boolean],
      default: true,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return;
      }
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="less">
.reg-checkbox {
  display: inline-block;
  border: 2px solid @lp-outline-color;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 0.6em;
    height: 0.8em;
    border: 0.2em solid lighten(@lp-outline-color, 20%);
    transform: translate(-50%, -100%) rotateZ(-45deg);
    border-left: 0;
    border-top: 0;
    left: 50%;
    top: 50%;
    margin-top: -0.2em;
    margin-left: 0.1em;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    pointer-events: none;
  }

  &.is-checked::before {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(45deg);
  }

  &.is-disabled {
    opacity: 0.25;
    border-color: #dedede;
  }
}
</style>
