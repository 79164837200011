import { PROFILE_SELECT_STAGE } from "@/constants";
import dayjs from "@/plugins/dayjs";

export const ScheduleService = {
  checkSelectStage(id, schedule = [], isJunior = false) {
    if (!schedule?.length) return;

    const commonStage = schedule.filter(
      (n) => n.stage === PROFILE_SELECT_STAGE && n.profile === null
    );
    const specificStage = schedule.filter(
      (n) => n.stage === PROFILE_SELECT_STAGE && n.profile === id
    );
    const range = {
      start: specificStage?.start_at || commonStage?.start_at,
      end: specificStage?.end_at || commonStage?.end_at,
    };

    if (range.start && dayjs().isBefore(dayjs(range.start))) {
      return `Этап выбора ${isJunior ? "сферы" : "профиля"} еще не начался`;
    }

    if (range.end && dayjs().isAfter(dayjs(range.end))) {
      return `Этап выбора ${isJunior ? "сферы" : "профиля"} уже завершен`;
    }
  },
};
