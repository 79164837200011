<template>
  <div
    class="reg-profile"
    :class="{ 'is-selected': selected }">
    <RegCheckbox
      class="reg-profile__check"
      :checked="selected"
      :value="id"
      :disabled="isDisabled"
      @input="(val) => $emit('select', val)" />
    <div class="reg-profile__data">
      <div class="reg-profile__title">
        <slot></slot>
      </div>
      <div class="reg-profile__meta">
        <slot name="meta"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import RegCheckbox from "./RegCheckbox";
export default {
  name: "RegProfile",
  components: { RegCheckbox },
  props: {
    id: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isDisabled() {
      return !this.selected && !this.selectable;
    },
  },
};
</script>

<style lang="less" scoped>
.reg-profile {
  padding: 21px;
  border-bottom: 1px solid @lp-outline-color;
  display: flex;
  flex-direction: row;
  background-color: fade(@lp-outline-color, 8%);

  &__check {
    margin-right: 20px;
  }

  &__data {
    flex-grow: 1;
  }

  &__title {
    font-weight: bold;
    color: lighten(@lp-base-color, 3%);
    margin-bottom: 5px;
  }
}
</style>
